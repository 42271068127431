<template>
  <v-btn
    :class="['dialog-base-btn', `${type}-btn`]"
    :outlined="isOutlined"
    :depressed="!isOutlined"
    :rounded="isRounded"
    :color="color"
    :width="width"
    :height="height ? height : isRounded ? '40px' : '50px'"
    :disabled="disabled"
    @click="$emit('click')"
    >{{ text }}</v-btn
  >
</template>

<script>
export default {
  name: "BaseBtn",
  props: {
    text: {
      type: String,
      default: "",
      require: true,
    },
    type: {
      type: String,
      default: "normal", //'normal', 'submit', 'back'
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isRounded: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary", //#888, primary
    },
    width: {
      type: String,
      default: "267px",
    },
    height: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.dialog-base-btn {
  &.v-btn {
    border-radius: 0;
    font-size: 20px !important;
    font-weight: 500;
    margin-bottom: 15px;
    .v-btn__content {
      line-height: 1.2;
      position: relative;
      top: -2px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.v-btn--rounded {
      border-radius: 50px;
    }
  }
}
.class-btn {
  &.v-btn {
    font-size: 18px !important;
    .v-btn__content {
      color: #fff !important;
    }
  }
}
.back-btn {
  &.v-btn {
    background-color: rgba(#c3131b, 0.2);
    .v-btn__content {
      color: #fff !important;
    }
  }
}
</style>