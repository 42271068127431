<template>
  <Dialog
    class="invoice-select-dialog"
    :dialog="openDialog"
    :title="invoiceType.text"
  >
    <div class="invoice-select-dialog_content">
      <p class="invoice-select-dialog_content_notice">提醒：</p>
      <p v-html="invoiceType.notice"></p>
    </div>
    <DialogBaseBtn
      text="了解！我要登錄"
      :isRounded="true"
      @click="routeTo(invoiceType.route)"
    />
    <DialogBaseBtn
      text="關閉視窗"
      :isRounded="true"
      :isOutlined="true"
      @click="$emit('close')"
    />
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
import DialogBaseBtn from "@/components/DialogBaseBtn.vue";

export default {
  name: "InvoiceSelectDialog",
  components: {
    Dialog,
    DialogBaseBtn,
  },
  props: {
    openDialog: {
      type: Boolean,
      require: false,
    },
    invoiceType: {
      type: Object,
      require: {},
    },
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss">
.invoice-select-dialog {
  &_content {
    margin: 10px 0 36px;
    &_notice {
      margin-bottom: 6px !important;
      font-weight: 500;
    }
  }
}
</style>