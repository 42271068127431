<template>
  <div class="invoice-log page-container">
    <PageTitle title="選擇登錄發票類型" />
    <div class="activity_content"></div>
    <BaseBtn
      v-for="(item, i) in invoiceTypeList"
      :key="i"
      :text="item.text"
      type="class"
      :isOutlined="true"
      color="#888"
      @click="selectType(item)"
    />
    <BaseBtn
      text="回到首頁"
      type="back"
      :isOutlined="true"
      color="primary"
      class="mt-16"
      @click="routeTo('/home')"
    />
    <InvoiceSelectDialog
      :invoiceType="currentDialog"
      :open-dialog="openDialog"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import BaseBtn from '@/components/BaseBtn.vue'
import InvoiceSelectDialog from '@/components/InvoiceSelectDialog.vue'

export default {
  name: 'Home',
  components: {
    PageTitle,
    BaseBtn,
    InvoiceSelectDialog
  },
  data() {
    return {
      invoiceTypeList: [
        {
          text: '電子發票',
          notice: '財政部電子發票資料更新時間<br>至多需7-10個工作天之審核時間',
          route: '/electricInvoice'
        },
        {
          text: '雲端載具',
          notice: '雲端載具採人工審核，<br>至多需5-7個工作天進行審核作業',
          route: '/carrier'
        },
        {
          text: '傳統發票',
          notice: '傳統發票採人工審核，<br>至多需5-7個工作天進行審核作業',
          route: '/tradInvoice'
        }
      ],
      openDialog: false,
      currentDialog: {}
    }
  },
  methods: {
    selectType(type) {
      this.currentDialog = type
      this.openDialog = true
    },
    closeDialog() {
      this.currentDialog = {}
      this.openDialog = false
    },
    routeTo(route) {
      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss">
.invoice-log {
}
</style>
